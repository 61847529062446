html { scroll-behavior: smooth; }

.splide__arrow svg {
    display: none;
}

.splide__arrow--prev {
    background-color: black;
    background-image: url('/src/assets/icons/ArrowLeftIcon.svg');
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    fill: #fff;
}

.splide__arrow--next {
    background-color: black;
    background-image: url('/src/assets/icons/ArrowRightIcon.svg');
    background-repeat: no-repeat;
    background-position: center;
    fill: #fff;
    color: white;
}

.splide__arrow {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
